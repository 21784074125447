import { createSlice } from "@reduxjs/toolkit";
import { properties } from "../components/Utility/ApiMethod";

const StripeSlice = createSlice({
  name: "stripe",
  initialState: {
    stripeAmount: {},
    LOADING: false,
    ERROR: false,
  },
  reducers: {
    setStripeAmount(state, action) {
      state.stripeAmount = action.payload;
    },
    setLoading(state, action) {
      state.LOADING = action.payload;
    },
    setError(state, action) {
      state.ERROR = action.payload;
    },
  },
});

export const { setStripeAmount, setLoading, setError } = StripeSlice.actions;
export default StripeSlice.reducer;
