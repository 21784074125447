import React, { Fragment } from 'react'
import { Paper, Box, Grid, Typography } from '@material-ui/core'

export default function NotFound(props) {

  const handleClick = () => {
    if (props.history.length > 2) {
      return props.history.goBack();
    }
    props.history.push('/')
  }

  return (
    <Fragment>
      <div style={{ textAlign: "center" }}>
        <Box m={1} p={2}>
          <Paper elevation={3}>
            <Box m={1} p={2}>
              <h1>Oops</h1>
              <Grid container>
                <Grid item xs={12}>
                  <img src="/images/404.gif" alt="404" />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2">
                    The page you are trying to access doesn't exist.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    style={{ cursor: "pointer" }}
                    variant="body2"
                    onClick={handleClick}>&#171; Back</Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </div>
    </Fragment>
  )
}