import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "./../../../utils/AppContext";
import { Header } from "./Header";
// bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';
import { makeStyles } from "@material-ui/core";
import { Paper, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

// Routes accessible only if logged in
const Layout = ({ component: Component, policy, ...rest }) => {
  const [drawerState, setDrawerState] = useState(true);
  const classes = useStyles();

  return (
    <AuthConsumer>
      {({ isAuth, hasAccess, rightBarState }) => {
        let redirectPath;
        if (rest.path === "/auth/logout" || rest.path === "/") {
          redirectPath = "";
        }
        if (!isAuth) {
          return <Redirect to={`/auth/login`} />;
        }

        return (
          <Route
            render={(props) => {
              const root = document.getElementById("root");
              const routePattern = props.match.path
                .replace(/([^a-z])/gi, "-")
                .replace(/([-]{2,})/g, "-")
                .replace(/^-+|-+$/g, "");

              root.setAttribute("data-route-pattern", routePattern);

              return (
                <React.Fragment>
                  <div className={classes.root}>
                    <Header
                      {...props}
                      onDrawerToggle={(state) => {
                        setDrawerState(state);
                      }}
                    />
                    <main
                      className={`${classes.content} contenter-main ${
                        rightBarState ? "right-sidebar-active" : ""
                      } ${drawerState ? "drawer-open" : "drawer-close"}`}
                    >
                      <div className={classes.toolbar} />
                      <Paper className="contant-box" elevation={2}>
                        <Box component="div" p={1} m={1}>
                          {!hasAccess(policy) ? (
                            <div style={{ marginTop: "60px" }}>
                              <h1>
                                Please contact the admin to get access to
                                permissions.
                              </h1>
                            </div>
                          ) : (
                            <Component {...props} />
                          )}
                        </Box>
                      </Paper>
                    </main>
                  </div>
                </React.Fragment>
              );
            }}
            {...rest}
          />
        );
      }}
    </AuthConsumer>
  );
};

export { Layout };
