import { createSlice } from "@reduxjs/toolkit";

const ApplyPropertySlice = createSlice({
  name: "applyProperty",
  initialState: {
    stepperSteps: 0,
    coApplicantPayment: true,
    presetLabels: {},
    petPaymentStatus: false,
    acutraqFee: 0,
    guarantorFee: 0,
    applicantVerified: false,
    merchantConfiguration: false,
    logo: "",
    stripeAmountApply: {},
    floorActive: false,
    personalInfo: {
      yourInfo: false,
      flag: 0,
      count: -1,
      submit: false,
    },
    addressHistory: {
      crrntInfo: false,
      flag: 0,
      count: -1,
      disablePrevAddress: false,
      submit: false,
    },
    employmentInfo: {
      employInfo: false,
      flag: 0,
      count: -1,
      disablePrevEmployer: false,
      submit: false,
    },
    feeConfig: {},
    floorPlan: [],
    preDisc: {},
    welcome: {},
    signature: {},
    formPresetData: {},
    propertyInformation: {},
    yourInformation: {},
    information: {},
    merchantAccount: "",
    currentAddress: {},
    previousAddress: [],
    hidePreviousAddress: {},
    hidePreviousEmployer: {},
    employmentStatus: {},
    currentEmployer: [],
    previousEmployers: [],
    otherSourcesIncome: [],
    pets: [],
    vehicles: [],
    bankAccount: {},
    personalReferences: [],
    emergencyContacts: [],
    otherQuestions: {},
    payeeName: {},
    adult: [],
    minor: [],
    guarantor: [],
    LOADING: false,
    ERROR: false,
  },
  reducers: {
    setStepperSteps(state, action) {
      state.stepperSteps = action.payload;
    },
    setHidePreviousAddress(state, action) {
      state.hidePreviousAddress = action.payload;
    },
    setHidePreviousEmployer(state, action) {
      state.hidePreviousEmployer = action.payload;
    },
    setAcutraqFee(state, action) {
      state.acutraqFee = action.payload;
    },
    setGuarantorFee(state, action) {
      state.guarantorFee = action.payload;
    },
    setPresetLabels(state, action) {
      state.presetLabels = action.payload;
    },
    setCoApplicantPayment(state, action) {
      state.coApplicantPayment = action.payload;
    },
    setFloorActive(state, action) {
      state.floorActive = action.payload;
    },
    setPetPaymentStatus(state, action) {
      state.petPaymentStatus = action.payload;
    },
    setMerchantConfiguration(state, action) {
      state.merchantConfiguration = action.payload;
    },
    setFloorPlan(state, action) {
      state.floorPlan = action.payload;
    },
    setPersonalInfo(state, action) {
      state.personalInfo = action.payload;
    },
    setAddressHistory(state, action) {
      state.addressHistory = action.payload;
    },
    setEmploymentInfo(state, action) {
      state.employmentInfo = action.payload;
    },
    setFeeConfig(state, action) {
      state.feeConfig = action.payload;
    },
    setPreDisc(state, action) {
      state.preDisc = action.payload;
    },
    setLogo(state, action) {
      state.logo = action.payload;
    },
    setStripeAmountApply(state, action) {
      state.stripeAmountApply = action.payload;
    },
    setAdult(state, action) {
      state.adult = action.payload;
    },
    setGuarantor(state, action) {
      state.guarantor = action.payload;
    },
    setMinor(state, action) {
      state.minor = action.payload;
    },
    setFormPresetData(state, action) {
      state.formPresetData = action.payload;
    },
    setWelcome(state, action) {
      state.welcome = action.payload;
    },
    setSignature(state, action) {
      state.signature = action.payload;
    },
    setPropertyInformation(state, action) {
      state.propertyInformation = action.payload;
    },
    setYourInformation(state, action) {
      state.yourInformation = action.payload;
    },
    setInformation(state, action) {
      state.information = action.payload;
    },
    setCurrentAddress(state, action) {
      state.currentAddress = action.payload;
    },

    setPreviousAddress(state, action) {
      state.previousAddress = action.payload;
    },
    setEmploymentStatus(state, action) {
      state.employmentStatus = action.payload;
    },

    setCurrentEmployer(state, action) {
      state.currentEmployer = action.payload;
    },

    setPreviousEmployers(state, action) {
      state.previousEmployers = action.payload;
    },

    setOtherSourcesIncome(state, action) {
      state.otherSourcesIncome = action.payload;
    },

    setPets(state, action) {
      state.pets = action.payload;
    },

    setVehicles(state, action) {
      state.vehicles = action.payload;
    },

    setBankAccount(state, action) {
      state.bankAccount = action.payload;
    },
    setApplicantVerified(state, action) {
      state.applicantVerified = action.payload;
    },

    setPersonalReferences(state, action) {
      state.personalReferences = action.payload;
    },

    setEmergencyContacts(state, action) {
      state.emergencyContacts = action.payload;
    },
    setMerchantAccount(state, action) {
      state.merchantAccount = action.payload;
    },
    setOtherQuestions(state, action) {
      state.otherQuestions = action.payload;
    },
    setPayeeName(state, action) {
      state.otherQuestions = action.payload;
    },

    setLoading(state, action) {
      state.LOADING = action.payload;
    },
    setError(state, action) {
      state.ERROR = action.payload;
    },
  },
});

export const {
  setCoApplicantPayment,
  setStripeAmountApply,
  setPetPaymentStatus,
  setFeeConfig,
  setPresetLabels,
  setAcutraqFee,
  setGuarantorFee,
  setMerchantConfiguration,
  setMerchantAccount,
  setPreDisc,
  setPersonalInfo,
  setAddressHistory,
  setEmploymentInfo,
  setFloorPlan,
  setLogo,
  setWelcome,
  setStepperSteps,
  setHidePreviousAddress,
  setHidePreviousEmployer,
  setApplicantVerified,
  setAdult,
  setGuarantor,
  setMinor,
  setFloorActive,
  setFormPresetData,
  setSignature,
  setPayeeName,
  setPropertyInformation,
  setYourInformation,
  setInformation,
  setCurrentAddress,
  setPreviousAddress,
  setEmploymentStatus,
  setCurrentEmployer,
  setPreviousEmployers,
  setOtherSourcesIncome,
  setVehicles,
  setPets,
  setBankAccount,
  setPersonalReferences,
  setEmergencyContacts,
  setOtherQuestions,
  setLoading,
  setError,
} = ApplyPropertySlice.actions;
export default ApplyPropertySlice.reducer;
