import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import backend from "i18next-xhr-backend";
import http from "./utils/http";
import Backend from "i18next-http-backend";
// import config from "./utils/config";
// const apiEndpoint = config.endPoint;
// const apiEndpoint = process.env.REACT_APP_API_URL;

const language = localStorage.getItem("language") || "en";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next

  .init({
    // resources,
    lng: language,
    fallbackLng: "en",
    fallbackNS: "translation",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    // backend: {
    //   loadPath: `${apiEndpoint}/languages/{{lng}}/{{ns}}.json`,

    //   // path to post missing resources
    //   addPath: `${apiEndpoint}/languages/{{lng}}/{{ns}}.json`,

    //   allowMultiLoading: true,

    //   parse: function (data) {
    //     return JSON.parse(data);
    //   },

    //   //parse data before it has been sent by addPath
    //   parsePayload: function (ns, key, value) {
    //     return { ns, key, value };
    //   },

    //   request: async (options, url, payload, callback) => {
    //     if (payload) {
    //       if (window.logMissing) {
    //         // post missing
    //         try {
    //           const response = await http.post(url, payload);
    //           if (response.data === "NOK") {
    //             window.logMissing = false;
    //           }
    //         } catch (error) {
    //           callback(error);
    //           console.log(payload);
    //         }
    //       }
    //     } else {
    //       try {
    //         const res = await http.get(url);
    //         callback(null, res);
    //       } catch (error) {
    //         callback(error);
    //       }
    //     }
    //   },
    // },

    saveMissing: true,
    // save locally the missing keys and send to server. || Removed - using backend now
    _missingKeyHandler: (lng, ns, key, fallback) => {
      if (!window.missingLanguage[ns]) window.missingLanguage[ns] = {};
      if (!window.missingLanguage[ns][key]) {
        window.missingLanguage[ns][key] = fallback;
        console.log("missingKeyHandler", ns, key, fallback);
      }
    },
  });

window.logMissing = false;
// save locally the missing keys and send to server. || Removed - using backend now
window._missingLanguage = {};

export default i18n;
