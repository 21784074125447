import React, { Suspense, lazy, useEffect } from "react";
import { Switch } from "react-router-dom";
import { AuthProvider } from "./utils/AppContext";

import NotFound from "./components/sample/NotFound";
import TestComponent from "./components/Test/TestComponent";
import MjmlPlayground from "./components/Test/MjmlPlayground";

import Login from "./components/Auth/Login/Login";
import Logout from "./components/Logout";

import { Layout as PrivateRoute } from "./components/Layouts/Private/Layout";
import { Layout as PublicRoute } from "./components/Layouts/Public/Layout";

import "./i18n";
import { useSnackbar } from "notistack";
import { AuthRoute } from "./utils/Routes";
import "./App.css";
import Impersonate from "./components/Account/Impersonate";

const preLoadComponents = [];
const ReactLazyPreload = (importStatement) => {
  const Component = lazy(importStatement);
  Component.preload = importStatement;
  preLoadComponents.push(Component);
  return Component;
};

const ForgotPassword = ReactLazyPreload(() =>
  import("./components/Auth/ForgotPassword")
);
const ResetPassword = ReactLazyPreload(() =>
  import("./components/Auth/ResetPassword")
);
const UserResetPaasword = ReactLazyPreload(() =>
  import("./components/Users/ResetPassword")
);
const FormPresets = ReactLazyPreload(() => import("./components/FormPresets"));

const Setting = ReactLazyPreload(() => import("./components/Client/Setting"));
const EditSetting = ReactLazyPreload(() =>
  import("./components/Client/EditSetting")
);
const BillingPlans = ReactLazyPreload(() =>
  import("./components/Billing/BillingPlans")
);

const Users = ReactLazyPreload(() => import("./components/Users/UsersList"));
const AddUser = ReactLazyPreload(() => import("./components/Users/AddUser"));
const UserEdit = ReactLazyPreload(() => import("./components/Users/EditUser"));
const Presets = ReactLazyPreload(() => import("./components/Presets"));
const PresetsWelcome = ReactLazyPreload(() =>
  import("./components/Presets/PresetsWelcome")
);
const AddClient = ReactLazyPreload(() =>
  import("./components/Client/AddClient")
);
const Disclouser = ReactLazyPreload(() =>
  import("./components/Library/Disclouser")
);
const Leaseform = ReactLazyPreload(() =>
  import("./components/Library/LeaseForm")
);
const LeaseAddenda = ReactLazyPreload(() =>
  import("./components/Library/LeaseAddenda")
);
const OtherDoc = ReactLazyPreload(() =>
  import("./components/Library/OtherDoc")
);
const EditLeaseForm = ReactLazyPreload(() =>
  import("./components/Library/EditLeaseForm")
);
const ViewLeaseForm = ReactLazyPreload(() =>
  import("./components/Library/ViewLeaseForm")
);
const InviteLease = ReactLazyPreload(() =>
  import("./components/Library/InviteLease")
);
const ViewLeasePdf = ReactLazyPreload(() =>
  import("./components/Library/ViewPdf")
);
const Signature = ReactLazyPreload(() =>
  import("./components/Library/Signature")
);
const IntialSignature = ReactLazyPreload(() =>
  import("./components/Library/InitialSignature")
);
const ElectronicSignature = ReactLazyPreload(() =>
  import("./components/Library/ElectronicSignature")
);
const PublicElectronicSignature = ReactLazyPreload(() =>
  import("./components/Library/PublicElectronicSignature")
);
const JotFormSignature = ReactLazyPreload(() =>
  import("./components/Household/JotformSignature")
);
const EsignConsent = ReactLazyPreload(() =>
  import("./components/Library/EsignConsent")
);
const DrawSignature = ReactLazyPreload(() =>
  import("./components/Library/DrawSignature")
);
const EditDisclouser = ReactLazyPreload(() =>
  import("./components/Library/EditDisclouser")
);
const PreSignature = ReactLazyPreload(() =>
  import("./components/Apply/PreGeneratedSignatures")
);
const ThankyouPage = ReactLazyPreload(() =>
  import("./components/Apply/Thankyou")
);
const LibraryTemplate = ReactLazyPreload(() =>
  import("./components/Library/LibraryTemplate")
);
const AddAccount = ReactLazyPreload(() =>
  import("./components/Account/AddMerchantAccount")
);

const EditAccount = ReactLazyPreload(() =>
  import("./components/Account/EditMerchantAccount")
);
const viewDeposit = ReactLazyPreload(() =>
  import("./components/Account/ViewDeposits")
);
const DetailedDeposit = ReactLazyPreload(() =>
  import("./components/Account/DetailedDeposits")
);

const AddProperty = ReactLazyPreload(() =>
  import("./components/Property/AddProperty")
);

const PropertTab = ReactLazyPreload(() =>
  import("./components/Property/Property")
);

const EditProperty = ReactLazyPreload(() =>
  import("./components/Property/EditProperty")
);
const TestEditPdf = ReactLazyPreload(() =>
  import("./components/Library/TestEditPdf")
);
const CanvasRenderPdf = ReactLazyPreload(() =>
  import("./components/Library/CanvasRenderPdf")
);
const CnavasPdf = ReactLazyPreload(() =>
  import("./components/Library/CanvasPdf")
);
const WelcomeProperty = ReactLazyPreload(() =>
  import("./components/Apply/WelcomePage")
);
const StepperRegular = ReactLazyPreload(() =>
  import("./components/Apply/StepperPropRegular")
);
const StandardWelcomeProperty = ReactLazyPreload(() =>
  import("./components/Apply/StandardWelcomePage")
);
const StepperProperty = ReactLazyPreload(() =>
  import("./components/Apply/StepperProp")
);
const PreDisclosure = ReactLazyPreload(() =>
  import("./components/Apply/PreDisclosure")
);
const ConfigProperty = ReactLazyPreload(() =>
  import("./components/Apply/SelectConfig")
);
const PropertyInfo = ReactLazyPreload(() =>
  import("./components/Apply/RegisterProperty")
);
const ApplicantInfo = ReactLazyPreload(() =>
  import("./components/Apply/ApplicantInfo")
);
const CurrentAddressInfo = ReactLazyPreload(() =>
  import("./components/Apply/CurrentAddress")
);
const FeeConfigList = ReactLazyPreload(() =>
  import("./components/FeeConfig/FeeConfigList")
);
const NewConfigList = ReactLazyPreload(() =>
  import("./components/FeeConfig/NewConfigList")
);
const EditFeeConfig = ReactLazyPreload(() =>
  import("./components/FeeConfig/EditFeeConfig")
);
const ListBgCheck = ReactLazyPreload(() =>
  import("./components/Background/List")
);
const CreateBgCheck = ReactLazyPreload(() =>
  import("./components/Background/Create")
);
const UpdateBgCheck = ReactLazyPreload(() =>
  import("./components/Background/Update")
);
const ListHousehold = ReactLazyPreload(() =>
  import("./components/Household/List")
);
const CreateHousehold = ReactLazyPreload(() =>
  import("./components/Household/HouseholdCreateTabs")
);
const HouseholdList = ReactLazyPreload(() =>
  import("./components/Household/Household")
);
const LeaseInfo = ReactLazyPreload(() =>
  import("./components/Household/LeaseInfo")
);
const ApplicationList = ReactLazyPreload(() =>
  import("./components/Application/AppList")
);
const AdminAppPendingList = ReactLazyPreload(() =>
  import("./components/Application/ApplicationPendingList")
);
const AdminAppArchiveList = ReactLazyPreload(() =>
  import("./components/Application/ApplicationArchiveList")
);
const ArchivedList = ReactLazyPreload(() =>
  import("./components/Application/ArchivedApplication")
);
const DeclinedApplicant = ReactLazyPreload(() =>
  import("./components/Application/DeclinedApplicant")
);
const ShowApplicant = ReactLazyPreload(() =>
  import("./components/Application/ShowApplicant")
);
const ViewEditApplicant = ReactLazyPreload(() =>
  import("./components/Application/EditViewApplicantDetails")
);
const ShowAdminApplicant = ReactLazyPreload(() =>
  import("./components/Application/ShowAdminApplicant")
);
const Leases = ReactLazyPreload(() => import("./components/Household/Leases"));
const Logs = ReactLazyPreload(() => import("./components/Household/Logs"));

const Active = ReactLazyPreload(() => import("./components/Client/Active"));
const InActive = ReactLazyPreload(() => import("./components/Client/Inactive"));
const Payment = ReactLazyPreload(() =>
  import("./components/StripePayments/checkoutPayment/Payment")
);
const PaymentProp = ReactLazyPreload(() =>
  import("./components/Apply/StripePayments/checkoutPayment/Payment")
);
const Completion = ReactLazyPreload(() =>
  import("./components/StripePayments/checkoutPayment/Completion")
);
const CompletionProp = ReactLazyPreload(() =>
  import("./components/Apply/StripePayments/checkoutPayment/Completion")
);
const BillingGeneral = ReactLazyPreload(() =>
  import("./components/Billing/General")
);

const BillingPendingCharges = ReactLazyPreload(() =>
  import("./components/Billing/PendingCharges")
);

const BillingInvoices = ReactLazyPreload(() =>
  import("./components/Billing/Invoices")
);

function App() {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTimeout(
      () => preLoadComponents.map((component) => component.preload()),
      5000
    );
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <AuthProvider snackbar={enqueueSnackbar}>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={Active}
            policy="super_admin"
          />
          <PrivateRoute
            path="/client/active"
            component={Active}
            policy={["super_admin"]}
          />
          <PrivateRoute
            path="/client/inactive"
            component={InActive}
            policy={["super_admin"]}
          />
          <PrivateRoute
            path="/client/add"
            component={AddClient}
            policy={["super_admin"]}
          />

          <PrivateRoute path="/auth/logout" component={Logout} />

          <AuthRoute path="/auth/login" component={Login} />
          <AuthRoute path="/auth/forgotPassword" component={ForgotPassword} />
          <PrivateRoute
            exact
            path="/billing/general"
            component={BillingGeneral}
            policy={["super_admin", "manage_users"]}
          />
          <PrivateRoute
            exact
            path="/billing/pending-charges"
            component={BillingPendingCharges}
            policy={["super_admin", "manage_users"]}
          />
          <PrivateRoute
            exact
            path="/billing/invoices"
            component={BillingInvoices}
            policy={["super_admin", "manage_users"]}
          />
          <PrivateRoute
            exact
            path="/admin/form-presets"
            component={FormPresets}
            policy={["super_admin", "manage_client"]}
          />

          <PrivateRoute
            exact
            path="/admin/form-presets/welcome/:id"
            component={PresetsWelcome}
            policy={["super_admin", "manage_users"]}
          />
          <PrivateRoute
            exact
            path="/setting"
            component={Setting}
            policy={["super_admin", "manage_users"]}
          />
          <PrivateRoute
            exact
            path="/admin/edit-setting"
            component={EditSetting}
            policy={["super_admin", "manage_client"]}
          />
          <PrivateRoute
            exact
            path="/billing-plans"
            component={BillingPlans}
            policy={["super_admin", "manage_client"]}
          />
          {/* Users Routes */}
          <PrivateRoute
            exact
            path="/admin/users/user-list"
            component={Users}
            policy={["super_admin", "manage_users"]}
          />
          <PrivateRoute
            exact
            path="/admin/users/add-user"
            component={AddUser}
            policy={["super_admin", "manage_users"]}
          />
          <PrivateRoute
            path="/admin/users/:id"
            component={UserEdit}
            policy={["super_admin", "manage_users"]}
          />
          <PrivateRoute
            exact
            path="/admin/form-presets/:id"
            component={Presets}
            policy={["super_admin", "manage_client"]}
          />
          <PrivateRoute
            exact
            path="/library/disclosures"
            component={Disclouser}
            policy={["super_admin", "edit_disclosures"]}
          />
          <PrivateRoute
            exact
            path="/library/lease-form"
            component={Leaseform}
            policy={["super_admin", "edit_disclosures"]}
          />
          <PrivateRoute
            exact
            path="/library/edit-lease-form/:id"
            component={EditLeaseForm}
            policy={["super_admin", "edit_disclosures"]}
          />
          <PrivateRoute
            exact
            path="/library/lease-addenda/:id"
            component={LeaseAddenda}
            policy={["super_admin", "edit_disclosures"]}
          />
          <PrivateRoute
            exact
            path="/library/other-doc"
            component={OtherDoc}
            policy={["super_admin", "edit_disclosures"]}
          />
          <PrivateRoute
            exact
            path="/library/show/:id"
            component={EditDisclouser}
            policy={["super_admin", "edit_disclosures"]}
          />
          <PrivateRoute
            exact
            path="/library/librarytemplate/:id"
            component={LibraryTemplate}
            policy={["super_admin", "edit_disclosures"]}
          />
          <PrivateRoute
            exact
            path="/admin/account/add-account"
            component={AddAccount}
            policy={["super_admin", "manage_client"]}
          />
          <PrivateRoute
            exact
            path="/admin/account/:id"
            component={EditAccount}
            policy={["super_admin", "manage_client"]}
          />
          <PrivateRoute
            exact
            path="/account/view-deposits/:id"
            component={viewDeposit}
            policy={["super_admin", "manage_client"]}
          />
          <PrivateRoute
            exact
            path="/account/merchant/transaction/:id"
            component={DetailedDeposit}
            policy={["super_admin", "manage_client"]}
          />
          <PrivateRoute
            exact
            path="/property/property-details/:id"
            component={PropertTab}
            policy={["super_admin", "manage_properties"]}
          />
          <PrivateRoute
            exact
            path="/property/add-property"
            component={AddProperty}
            policy={["super_admin", "manage_properties"]}
          />
          <PrivateRoute
            exact
            path="/property/edit-property/:id"
            component={EditProperty}
            policy={["super_admin", "manage_users"]}
          />
          <PrivateRoute
            exact
            path="/applicants/archive"
            component={ArchivedList}
            policy={["super_admin", "see_all_application"]}
          />
          <PrivateRoute
            exact
            path="/applicants/declined"
            component={DeclinedApplicant}
            policy={["super_admin", "see_all_application"]}
          />
          <PrivateRoute
            exact
            path="/applicants/active"
            component={ApplicationList}
            policy={["super_admin", "see_all_application"]}
          />
          <PrivateRoute
            exact
            path="/applicants-admin/active"
            component={AdminAppPendingList}
            policy={["super_admin"]}
          />
          <PrivateRoute
            exact
            path="/applicants-admin/archived"
            component={AdminAppArchiveList}
            policy={["super_admin"]}
          />
          <PrivateRoute
            exact
            path="/applicants-admin/show/:id"
            component={ShowAdminApplicant}
            policy={["super_admin"]}
          />
          <PrivateRoute
            exact
            path="/applicants/show/:id"
            component={ShowApplicant}
            policy={["super_admin", "see_all_application"]}
          />
          <PrivateRoute
            exact
            path="/view-edit/applicant/:id"
            component={ViewEditApplicant}
            policy={["super_admin", "see_all_application"]}
          />
          <PrivateRoute
            exact
            path="/admin/fee-config"
            component={FeeConfigList}
            policy={["super_admin", "manage_client"]}
          />
          <PrivateRoute
            exact
            path="/admin/fee-config"
            component={FeeConfigList}
            policy={["super_admin", "manage_client"]}
          />
          <PrivateRoute
            exact
            path="/admin/fee-config/add"
            component={NewConfigList}
            policy={["super_admin", "manage_client"]}
          />
          <PrivateRoute
            exact
            path="/admin/fee-config/edit/:id"
            component={EditFeeConfig}
            policy={["super_admin", "manage_client"]}
          />
          <PrivateRoute
            exact
            path="/library/editpdf/:id"
            component={TestEditPdf}
            policy={["super_admin", "manage_users"]}
          />
          <PrivateRoute
            exact
            path="/library/:id"
            component={CanvasRenderPdf}
            policy={["super_admin", "edit_disclosures"]}
          />
          <PrivateRoute
            exact
            path="/library/canvas"
            component={CnavasPdf}
            policy={["super_admin", "edit_disclosures"]}
          />

          <PublicRoute
            exact
            path="/property/apply/regular/:uid"
            component={StepperRegular}
          />
          <PublicRoute
            exact
            path="/property/apply/regular/generate_signature/:uid"
            component={PreSignature}
          />
          <PublicRoute
            exact
            path="/property/apply/standard/thank_you"
            component={ThankyouPage}
          />
          <PublicRoute
            exact
            path="/property/apply/:uid"
            component={StandardWelcomeProperty}
          />

          <PublicRoute
            exact
            path="/property/apply/standard/:uid"
            component={StepperProperty}
          />

          <PublicRoute
            exact
            path="/property/apply/standard/Pre_disclosure/:uid"
            component={PreDisclosure}
          />
          <PublicRoute
            exact
            path="/property/apply/property-info/:uid"
            component={PropertyInfo}
          />
          <PublicRoute
            exact
            path="/property/apply/applicant-info/:uid"
            component={ApplicantInfo}
          />
          <PublicRoute
            exact
            path="/property/apply/current-address-info/:uid"
            component={CurrentAddressInfo}
          />
          <PrivateRoute
            exact
            path="/admin/background/list"
            component={ListBgCheck}
          />
          <PrivateRoute
            exact
            path="/admin/background/create"
            component={CreateBgCheck}
          />
          <PrivateRoute
            exact
            path="/admin/background/update/:id"
            component={UpdateBgCheck}
          />
          <PrivateRoute exact path="/households" component={ListHousehold} />
          <PrivateRoute
            exact
            path="/households/create/:id"
            component={CreateHousehold}
            policy={["super_admin", "generate_lease_form"]}
          />
          <PrivateRoute
            exact
            path="/households/household"
            component={HouseholdList}
          />
          <PrivateRoute
            exact
            path="/households/lease/sign/:id"
            component={Signature}
          />
          <PrivateRoute
            exact
            path="/households/applicant/sign/:id"
            component={JotFormSignature}
          />
          <PrivateRoute
            exact
            path="/households/e-sign-consent/:id"
            component={EsignConsent}
          />
          <PrivateRoute
            exact
            path="/households/draw-signature/:id"
            component={DrawSignature}
          />
          <PrivateRoute
            exact
            path="/households/lease/intial-sign/:id"
            component={IntialSignature}
          />
          <PrivateRoute
            exact
            path="/households/lease/elctronic-sign/:id"
            component={ElectronicSignature}
          />
          <PrivateRoute
            exact
            path="/households/view-lease/:id"
            component={ViewLeaseForm}
            policy={["super_admin", "edit_disclosures"]}
          />
          <PrivateRoute
            exact
            path="/households/view-pdf/:id"
            component={ViewLeasePdf}
            policy={["super_admin", "edit_disclosures"]}
          />
          <PrivateRoute
            exact
            path="/households/lease-info/:id"
            component={LeaseInfo}
          />
          <PrivateRoute exact path="/households/leases" component={Leases} />
          <PrivateRoute exact path="/households/logs" component={Logs} />
          <PublicRoute path="/apply/payment/:uid" component={PaymentProp} />
          <PublicRoute
            path="/apply/completion/:uid"
            component={CompletionProp}
          />
          <PublicRoute
            exact
            path="/households/invite-lease/:id"
            component={InviteLease}
          />
          <PublicRoute
            exact
            path="/households/lease/signature/:id"
            component={PublicElectronicSignature}
          />
          <PrivateRoute path="/payment/:id" component={Payment} />
          <PrivateRoute path="/completion/:id" component={Completion} />

          <AuthRoute
            exact
            path="/password-reset/:token"
            component={ResetPassword}
          />
          <PrivateRoute
            path="/reset-password/:id"
            component={UserResetPaasword}
            policy={["super_admin", "manage_users"]}
          />
          <PublicRoute exact path="/impersonate" component={Impersonate} />
          <PublicRoute path="/test" component={TestComponent} />

          <PublicRoute path="/mjml-playground" component={MjmlPlayground} />
          <PublicRoute exact path="*" component={NotFound} />
        </Switch>
      </AuthProvider>
    </Suspense>
  );
}

function Loading() {
  return <div className="suspense-loading">Loading</div>;
}

export default App;
