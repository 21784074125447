import React from "react";
import { useEffect } from "react";

function Impersonate() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userParams = params.get("user");
  useEffect(() => {
    localStorage.setItem("user", userParams);
    const user = JSON.parse(userParams);
    if (user.policies.includes("super_admin") && !user.isImpersonating) {
      window.location.href = "/client/active";
    } else {
      window.location.href = "/applicants/active";
    }
  }, []);

  return <></>;
}

export default Impersonate;
