import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../utils/AppContext'
import { useSnackbar } from 'notistack';

export default function Logout() {
  const context = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    context.logout();

    enqueueSnackbar('Logged out', {
      variant: 'success'
    });
  });

  return (
    <>Logging out securely</>
  )
}