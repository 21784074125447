import { configureStore } from "@reduxjs/toolkit";
import houseHoldSlice from "../redux/HouseholdSlice";
import propertySlice from "../redux/PopertySlice";
import applicationFormSlice from "../redux/ApplicationFormSlice";
import StripeSlice from "./StripeSlice";
import ApplyPropertySlice from "./ApplyPropertySlice";

export const store = configureStore({
  reducer: {
    houseHold: houseHoldSlice,
    property: propertySlice,
    applicationForm: applicationFormSlice,
    stripe: StripeSlice,
    applyProperty: ApplyPropertySlice,
  },
});
