import { createSlice } from "@reduxjs/toolkit";
import { properties } from "../components/Utility/ApiMethod";

const propertySlice = createSlice({
  name: "property",
  initialState: {
    properties: [],
    property: {},
    LOADING: false,
    ERROR: false,
  },
  reducers: {
    setProperties(state, action) {
      state.properties = action.payload;
    },
    setProperty(state, action) {
      state.property = action.payload;
    },
    setLoading(state, action) {
      state.LOADING = action.payload;
    },
    setError(state, action) {
      state.ERROR = action.payload;
    },
  },
});

export const {
  setProperties,
  setProperty,
  setLoading,
  setError,
} = propertySlice.actions;
export default propertySlice.reducer;

export function fetchProperties() {
  return async function fetchPropertiesThunk(dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await properties();
      dispatch(setProperties(data.PropertiesData));
      dispatch(setProperty(data.PropertiesData));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setError(true));
    }
  };
}
