import React, { Fragment, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Link as MLink,
  Typography,
  CssBaseline,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormikTextField from "../../Shared/FormikTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import http from "../../../utils/http";
import { getQuery } from "../../../utils/searchParams";
import { AuthContext } from "../../../utils/AppContext";
import { useSnackbar } from "notistack";
import ProgressButton from "../../Shared/ProgressButton";
import FormikPasswordField from "../../Shared/FormikPasswordField";
import { Trans, useTranslation } from "react-i18next";
import LabelCheckbox from "../../Shared/LabelCheckbox";
import css from "./Login.module.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  input: {
    margin: theme.spacing(1, 0, 2),
  },
}));

export default function Login(props) {
  const context = useContext(AuthContext);
  const { t } = useTranslation("login");
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const formData = {
    email:
      process.env.NODE_ENV === "development" ? "developer@conspecture.com" : "",
    password: process.env.NODE_ENV === "development" ? "123456" : "",
    remember_me: false,
  };

  const queryParams = getQuery(props.location.search);
  if (queryParams) {
    if (queryParams.email) {
      formData.email = queryParams.email;
    }
    if (queryParams.password) {
      formData.password = queryParams.password;
    }
  }

  const formValidation = yup.object({
    email: yup
      .string()
      .required("This field is required!")
      .email("Enter valid email address"),
    password: yup.string().required("This field is required!"),
    user_type: yup.string(),
    remember_me: yup.boolean(),
  });

  const [validationState, setValidationState] = useState({
    message: "",
    type: "error",
  });

  const loginSubmit = async (values, actions) => {
    try {
      const response = await http.post(`/auth/login`, values);
      enqueueSnackbar(
        <Trans ns="login" i18nKey="Logged in successfully."></Trans>,
        {
          variant: "success",
        }
      );

      // login user
      context.login(response.data);
    } catch (error) {
      setValidationState({
        type: "error",
        message: error.data.message,
      });
      actions.setSubmitting(false);
    }
  };

  return (
    <div className={css["login-main"]}>
      <Fragment>

        
        <div className={css["login-logo"]}>
          <img src="/images/AcutrqImage.png" alt="Classic" width={200} />
        </div>
        <Container className="login-contain-area" component="main">
          <CssBaseline />

          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              <Trans ns="login" i18nKey="header">
                Sign In
              </Trans>
            </Typography>
            {validationState.message && (
              <Alert
                severity={validationState.type}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "5px",
                }}
              >
                <Trans ns="login" i18nKey={validationState.message}></Trans>
              </Alert>
            )}
            <Formik
              initialValues={formData}
              validationSchema={formValidation}
              validateOnMount={true}
              onSubmit={loginSubmit}
            >
              {(props) => (
                <Form className={`${classes.form} form-wrap`}>
                  <FormikTextField
                    ns="login"
                    name="email"
                    type="email"
                    variant="outlined"
                    label="Email"
                    placeholder={t("login:Email")}
                    fullWidth
                    required
                    className={classes.input}
                    autoComplete="off"
                  />

                  <FormikPasswordField
                    ns="login"
                    name="password"
                    label="Password"
                    placeholder={t("login:Password")}
                    required
                    variant="outlined"
                    className={`${classes.input} ${css["password-icon"]}`}
                    id="outlined-secondary"
                    fullWidth
                  />
                  <div hidden>
                    <LabelCheckbox
                      name="remember_me"
                      color="primary"
                      label="Remember Me"
                    />
                  </div>
                  <Grid container justify="space-between">
                    <Grid item>
                      <MLink
                        className={`${css["forgot-password"]} ${css["mb-forgot-password"]}`}
                        to="/auth/forgotPassword"
                        variant="body2"
                        component={Link}
                      >
                        <Trans ns="login" i18nKey="forgotpass.txt">
                          Forgot Password?
                        </Trans>
                      </MLink>
                    </Grid>
                    <Grid item>
                      <ProgressButton
                        type="submit"
                        fullWidth
                        color="primary"
                        disabled={!props.isValid || props.isSubmitting}
                        className={`${classes.content} form-button`}
                      >
                        <Trans ns="login" i18nKey="button.signin">
                          Sign In
                        </Trans>
                      </ProgressButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Fragment>
    </div>
  );
}
