const config = {
  appName: process.env.REACT_APP_APPNAME,
  endPoint: process.env.REACT_APP_API_URL,
  publicUrl: process.env.REACT_APP_PUBLIC_URL,
  title: (title) => {
    document.title = `${title} | ${config.appName}`;
  },
};

export default config;
