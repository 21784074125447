import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { useField } from "formik";
import { Trans } from "react-i18next";

/**
 * <FormikPasswordField
    ns="page_name"
    label='Password'
    name='password'
    required
    variant="outlined"
    placeholder='Password'
    fullWidth />
 */
const FormikPasswordField = (props) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  // TextField error when value is null
  if (field.value === null) {
    field.value = "";
  }
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <TextField
      {...field}
      {...props}
      helperText={<Trans ns={props.ns} i18nKey={errorText}></Trans>}
      error={!!errorText}
      type={showPassword ? "text" : "password"}
      autoComplete="new-password" // works cuz it tells browser this field is for setting new password, do don't prefill
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <i className="icon dashboardicon-view"></i>
              ) : (
                <i className="icon dashboardicon-disable-icon"></i>
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FormikPasswordField;
