import React from 'react'
/**
 * Example  
 * ```
 * const htmlContentFromApiResponse = '<strong>This is Test</strong>';
 * 
 * <div>{htmlContentFromApiResponse}</div>
 * // &lt;strong&gt;This is Test&lt;/strong&gt;
 * 
 * <RawHtml>{htmlContentFromApiResponse}</RawHtml>
 * // <strong>This is Test</strong>
 * ```
 * @returns dangerouslySetInnerHTML within span tag
 */
export default function RawHtml({children, ...props}) {
  const createMarkup = () => {
    return {
      __html: children
    }
  }

  return <span dangerouslySetInnerHTML={createMarkup()} {...props}></span>
}