// master list of policies (interface?)

const AvailablePolicies = [
  "super_admin",
  "see_all_application",
  "manage_client",
  "manage_users",
  "manage_properties",
  "edit_disclosures",
  "see_all_application",
  "manual_entry_edit_application",
  "view_report",
  "generate_lease_form",
];

export default AvailablePolicies;
