import { createSlice } from "@reduxjs/toolkit";

const ApplicationFormSlice = createSlice({
  name: "applicationForm",
  initialState: {
    stripeAmount: {},
    acutraqFee: {},
    merchantConfiguration: false,
    merchantAccount: "",
    propertyInformation: {},
    yourInformation: {},
    currentAddress: {},
    previousAddress: [],
    employmentStatus: {},
    currentEmployer: [],
    previousEmployers: [],
    otherSourcesIncome: [],
    pets: [],
    vehicles: [],
    bankAccount: {},
    personalReferences: [],
    emergencyContacts: [],
    otherQuestions: {},
    payeeName: {},
    LOADING: false,
    ERROR: false,
  },
  reducers: {
    setStripeAmount(state, action) {
      state.stripeAmount = action.payload;
    },
    setAcutraqFee(state, action) {
      state.acutraqFee = action.payload;
    },
    setMerchantConfiguration(state, action) {
      state.merchantConfiguration = action.payload;
    },
    setMerchantAccount(state, action) {
      state.merchantAccount = action.payload;
    },
    setPropertyInformation(state, action) {
      state.propertyInformation = action.payload;
    },
    setYourInformation(state, action) {
      state.yourInformation = action.payload;
    },
    setCurrentAddress(state, action) {
      state.currentAddress = action.payload;
    },

    setPreviousAddress(state, action) {
      state.previousAddress = action.payload;
    },
    setEmploymentStatus(state, action) {
      state.employmentStatus = action.payload;
    },

    setCurrentEmployer(state, action) {
      state.currentEmployer = action.payload;
    },

    setPreviousEmployers(state, action) {
      state.previousEmployers = action.payload;
    },

    setOtherSourcesIncome(state, action) {
      state.otherSourcesIncome = action.payload;
    },

    setPets(state, action) {
      state.pets = action.payload;
    },

    setVehicles(state, action) {
      state.vehicles = action.payload;
    },

    setBankAccount(state, action) {
      state.bankAccount = action.payload;
    },

    setPersonalReferences(state, action) {
      state.personalReferences = action.payload;
    },

    setEmergencyContacts(state, action) {
      state.emergencyContacts = action.payload;
    },

    setOtherQuestions(state, action) {
      state.otherQuestions = action.payload;
    },
    setPayeeName(state, action) {
      state.payeeName = action.payload;
    },

    setLoading(state, action) {
      state.LOADING = action.payload;
    },
    setError(state, action) {
      state.ERROR = action.payload;
    },
  },
});

export const {
  setStripeAmount,
  setAcutraqFee,
  setMerchantConfiguration,
  setMerchantAccount,
  setPayeeName,
  setPropertyInformation,
  setYourInformation,
  setCurrentAddress,
  setPreviousAddress,
  setEmploymentStatus,
  setCurrentEmployer,
  setPreviousEmployers,
  setOtherSourcesIncome,
  setVehicles,
  setPets,
  setBankAccount,
  setPersonalReferences,
  setEmergencyContacts,
  setOtherQuestions,
  setLoading,
  setError,
} = ApplicationFormSlice.actions;
export default ApplicationFormSlice.reducer;
