import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "./AppContext";
// bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';

// Redirect to / if they are logged in
const AuthRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ isAuth }) => {
      if (isAuth) {
        const user = JSON.parse(localStorage.getItem("user"));
        let subDomain = user?.sub_domain;

        if (subDomain?.length > 0) {
          subDomain = subDomain + ".";
        }
        window.location.href = `${window.location.protocol}//${subDomain}${
          process.env.REACT_APP_HOST_NAME
        }/impersonate?user=${JSON.stringify(user)}`;
      }

      return (
        <Route
          render={(props) => {
            const root = document.getElementById("root");
            const routePattern = props.match.path
              .replace(/([^a-z])/gi, "-")
              .replace(/([-]{2,})/g, "-")
              .replace(/^-+|-+$/g, "");
            // const routeUrl = props.match.url.replace(/([^a-z])/ig, '-').replace(/([-]{2,})/g, '-').replace(/^-+|-+$/g, '')

            root.setAttribute("data-route-pattern", routePattern);
            // root.setAttribute('data-route-url', routeUrl);

            return !isAuth ? <Component {...props} /> : <></>;
          }}
          {...rest}
        />
      );
    }}
  </AuthConsumer>
);

export { AuthRoute };
