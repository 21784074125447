import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./page.css";
import "./header.css";
import "./dashboard_icons.css";
// import "./i18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { StylesProvider } from "@material-ui/core";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Contex from "./components/Utility/Contex";
import { store } from "./redux/Store";
import { Provider } from "react-redux";

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(
  <React.Fragment>
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <StylesProvider injectFirst>
        <SnackbarProvider
          maxSnack={2}
          autoHideDuration={5000}
          disableWindowBlurListener={true}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          action={(key) => <CloseButton id={key} />}
        >
          <Contex>
            <Router>
              <Route path="/" component={App} />
            </Router>
          </Contex>
          <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        </SnackbarProvider>
      </StylesProvider>
    </Provider>
    {/* </React.StrictMode> */}
  </React.Fragment>,
  document.getElementById("root")
);

const CloseButton = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="Close notification"
      color="inherit"
      onClick={() => closeSnackbar(id)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
