import axios from "axios";
import config from "./config";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
  headers: {
    "content-type": "application/json",
  },
});

http.interceptors.request.use((config) => {
  if (localStorage.user) {
    const user = JSON.parse(localStorage.user);
    if (user && user.token) {
      config.headers["token"] = user.token;
    }
  }
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let response = error.response;
    if (!response) {
      response = {
        status: 0,
        data: error.message,
      };
    }

    if (localStorage.user && response.status === 401) {
      sessionExpiredCallback();
    }
    if (localStorage.user && response.status === 403) {
    }

    return Promise.reject(response);
  }
);

const sessionExpiredCallback = () => {
  localStorage.removeItem("user");
  window.location.reload();
};

export default http;
