import { createSlice } from "@reduxjs/toolkit";
import {
  getHouseHoldDetails,
  getHouseHoldDetail,
} from "../components/Utility/ApiMethod";

const houseHoldSlice = createSlice({
  name: "houseHold",
  initialState: {
    houseHolds: [],
    houseHold: {},
    lease: {},
    leaseData: {},
    householdApplicant: {},
    pdfData: [],
    updateLeaseData: {},
    signer: {},
    planMessage: false,
    LOADING: false,
    ERROR: false,
  },
  reducers: {
    setHouseholds(state, action) {
      state.houseHolds = action.payload;
    },
    setHouseholdApplicant(state, action) {
      state.householdApplicant = action.payload;
    },
    setPdfData(state, action) {
      state.pdfData = action.payload;
    },
    setLease(state, action) {
      state.lease = action.payload;
    },
    setUpdateLeaseData(state, action) {
      state.updateLeaseData = action.payload;
    },
    setLeaseData(state, action) {
      state.leaseData = action.payload;
    },
    setSigner(state, action) {
      state.signer = action.payload;
    },
    setHousehold(state, action) {
      state.houseHold = action.payload;
    },
    setPlanMessage(state, action) {
      state.planMessage = action.payload;
    },

    setLoading(state, action) {
      state.LOADING = action.payload;
    },
    setError(state, action) {
      state.ERROR = action.payload;
    },
  },
});

export const {
  setHouseholds,
  setHousehold,
  setSigner,
  setPdfData,
  setLease,
  setLeaseData,
  setPlanMessage,
  setLoading,
  setUpdateLeaseData,
  setHouseholdApplicant,
  setError,
} = houseHoldSlice.actions;
export default houseHoldSlice.reducer;

export function fetchHouseholds() {
  return async function fetchHouseholdsThunk(dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await getHouseHoldDetails();
      dispatch(setHouseholds(data.houseHoldData));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setPlanMessage(true));
      dispatch(setError(true));
    }
  };
}

export function fetchHousehold(id) {
  return async function fetchHouseholdsThunk(dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await getHouseHoldDetail(id);
      dispatch(setHousehold(data.houseHoldData));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);

      dispatch(setError(true));
    }
  };
}
