import React from "react";
import { useField } from "formik";
import { FormGroup, FormControlLabel, FormHelperText } from "@material-ui/core";
import StyledCheckbox from "./StyledCheckbox";

const LabelCheckbox = ({ value, label, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <StyledCheckbox {...field} {...props} value={value} color="primary" />
        }
        label={label}
      />
      {errorText && (
        <FormHelperText component="div" error={true}>
          {errorText}
        </FormHelperText>
      )}
    </FormGroup>
  );
};

export default LabelCheckbox;
