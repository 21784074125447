import React, { Fragment, useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import {
  makeStyles,
  useTheme,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Hidden,
} from "@material-ui/core";
import { AuthContext } from "../../../utils/AppContext";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./header.css";
import { withStyles } from "@material-ui/core/styles";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // menuButton: {
  //   marginRight: 36,
  // },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  mobileMenuRightToplink: {
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      color: "inherit",
      textDecoration: "none",
    },
  },
  nestedListMenu: {
    paddingLeft: theme.spacing(4),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 10,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export function Header(props) {
  const context = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let drawerDefaultState = window.screen.availWidth > 425;
  const orgLogo = localStorage.getItem("logo");
  if (!localStorage.getItem("drawerDefaultState")) {
    localStorage.setItem("drawerDefaultState", drawerDefaultState);
  }
  drawerDefaultState = JSON.parse(localStorage.getItem("drawerDefaultState"));
  const [editSetting, setEditSetting] = useState("#");

  useEffect(() => {
    props.onDrawerToggle(drawerDefaultState);

    if (
      context.isImpersonating ||
      !context.user?.policies?.includes("super_admin")
    ) {
      setEditSetting("/admin/edit-setting");
    }
    // run for first time (let parent know what the drawer state is from localstorage if exists)
    // eslint-disable-next-line
  }, []);

  const [drawerState, setDrawerState] = useState(drawerDefaultState);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const classes = useStyles();
  const theme = useTheme();

  const toggleDrawer = () => {
    localStorage.setItem("drawerDefaultState", !drawerState);

    if (props.onDrawerToggle) {
      props.onDrawerToggle(!drawerState);
    }

    setDrawerState(!drawerState);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const adminMenu = [
    {
      label: "Clients",
      to: "/client/active",
      className: "landlords",
      startsWith: "/client",
      policies: "super_admin",
    },
    {
      label: "Applicants",
      to: "/applicants-admin/active",
      className: "applicants",
      startsWith: "/applicants",
      policies: "super_admin",
    },
    {
      label: "Library",
      to: "/library/disclosures",
      className: "language",
      startsWith: "/library",
      policies: "super_admin",
    },
    {
      label: "Admin",
      to: "/admin/form-presets",
      className: "settings",
      startsWith: "/admin",
      policies: "super_admin",
    },
    {
      label: "Billing",
      to: "/billing/general",
      className: "policies",
      startsWith: "/billing",
      policies: "super_admin",
    },
  ];

  const userMenus = [
    {
      label: "Applicants",
      to: "/applicants/active",
      className: "applicants",
      startsWith: "/applicants",
      policies: "see_all_application",
    },
    {
      label: "Households",
      to: "/households",
      className: "properties",
      startsWith: "/households",
      policies: ["super_admin", "generate_lease_form"],
    },
    {
      label: "Properties",
      to: "/property/add-property",
      startsWith: "/property",
      className: "properties",
      policies: ["super_admin", "manage_properties"],
    },
    {
      label: "Library",
      to: "/library/disclosures",
      className: "language",
      startsWith: "/library",
      policies: ["super_admin", "edit_disclosures"],
    },

    {
      label: "Admin",
      to: "/admin/form-presets",
      className: "settings",
      startsWith: "/admin",
      policies: ["super_admin", "manage_client"],
    },
  ];

  const drawerMenus = context.isImpersonating ? userMenus : adminMenu;
  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      onClick={handleMobileMenuClose}
    >
      <MenuItem>
        <NavLink to="/profile" className={classes.mobileMenuRightToplink}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <span>Profile</span>
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/auth/logout" className={classes.mobileMenuRightToplink}>
          <IconButton color="inherit">
            <PowerSettingsNewIcon />
          </IconButton>
          <span>Logout</span>
        </NavLink>
      </MenuItem>
    </Menu>
  );

  // responsive
  const [mobileOpen, setMobileOpen] = React.useState(false);
  function toggleMobileDrawer() {
    setMobileOpen(!mobileOpen);
  }

  const list = (mobile = false) => (
    <React.Fragment>
      <List
        className="sidemenu-basic"
        onClick={mobile ? toggleMobileDrawer : null}
      >
        {drawerMenus.map((item, index) => {
          const Icon = item.icon;
          const { policies } = item;
          const hideMenu =
            policies && !context.hasAccess(policies) ? true : false;

          let selected = false;
          if (props.match.path === item.to) {
            // route exact match
            selected = true;
          } else if (item.route && props.match.path === item.route) {
            // route pattern match
            selected = true;
          } else if (props.match.path.startsWith(item.startsWith)) {
            // route startes with (nested)
            selected = true;
          }
          return (
            <React.Fragment key={index}>
              {!hideMenu && (
                <ListItem
                  button
                  to={item.to}
                  component={NavLink}
                  className={`icon-${item.className}`}
                  selected={selected}
                >
                  <Tooltip title={item.label}>
                    <ListItemIcon>{Icon}</ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={item.label} />
                </ListItem>
              )}
            </React.Fragment>
          );
        })}
      </List>

      {/* <List className="logout-menu">
        <ListItem button key={"Logout"} component={NavLink} to="/auth/logout">
          <ListItemText primary="Logout" />
        </ListItem>
      </List> */}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <CssBaseline />

      {/* for mobile */}
      {/* <Hidden smUp implementation="css">
        <Fragment>
          <div className="appbar-main mb-appbar-main">
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: drawerState,
                "appbar-shift": drawerState,
              })}
            >
              <Toolbar>
                <div className="mb-logo">
                  <img src="/images/logo.jpg" alt="Classic" />
                </div>
                <IconButton
                  onClick={toggleMobileDrawer}
                  edge="start"
                  color="inherit"
                  aria-label="toggle drawer"
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            {renderMobileMenu}
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={toggleMobileDrawer}
              className={clsx({
                [classes.drawerOpen]: mobileOpen,
                [classes.drawerClose]: !mobileOpen,
                "menu-drawer-open": mobileOpen,
                "menu-drawer-close": !mobileOpen,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: mobileOpen,
                  [classes.drawerClose]: !mobileOpen,
                  "menu-drawer-open": mobileOpen,
                  "menu-drawer-close": !mobileOpen,
                }),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <IconButton
                onClick={toggleMobileDrawer}
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
                component={NavLink}
                to="/profile"
                className={`${classes.mobileMenuRightToplink} mb-profile-pic`}
              >
                <Avatar
                  className={classes.avatar}
                  src={context.user.profile_pic}
                >
                  {context.user.initials}
                </Avatar>
                <Typography className="username-tx" variant="inherit">
                  {context.user.first_name} {context.user.last_name}
                </Typography>
              </IconButton>

              <IconButton
                onClick={toggleMobileDrawer}
                className={`${classes.closeMenuButton} mb-menu-close`}
              >
                <i className="icon dashboardicon-close"></i>
              </IconButton>
              {list(true)}
            </Drawer>
          </div>
        </Fragment>
      </Hidden> */}

      {/* for desktop */}
      <Hidden xsDown implementation="css">
        <Fragment>
          <div className="appbar-main">
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: drawerState,
                "appbar-shift": drawerState,
              })}
            >
              <Toolbar>
                <IconButton
                  onClick={toggleDrawer}
                  edge="start"
                  color="inherit"
                  aria-label="toggle drawer"
                >
                  {drawerState ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                {/* <Typography className="page-title" variant="h6" noWrap>
                  {context.title}
                </Typography> */}
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                  <div className="dropdown dropdown-test" onClick={handleClick}>
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      color="inherit"
                      component={NavLink}
                      // to="/profile"
                      to="#"
                      // to={editSetting}
                      id="edit_icon_btn"
                      className={classes.mobileMenuRightToplink}
                    >
                      <Avatar
                        className={classes.avatar}
                        src={context.user.profile_pic}
                      >
                        {context.user.initials}
                      </Avatar>
                    </IconButton>
                    <Typography
                      className="username-tx username-test"
                      variant="inherit"
                      style={{ color: "grey", fontWeight: "bold" }}
                    >
                      {context.user.first_name} {context.user.last_name}
                    </Typography>
                  </div>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {/* <NavLink
                            to={editSetting}
                            className={classes.mobileMenuRightToplink}
                            onClick={handleClose}
                          >
                            <StyledMenuItem>
                              <ListItemIcon>
                                <ListItemIcon>
                                  <i className="fa-solid fa-gear"></i>
                                </ListItemIcon>
                                <ListItemText primary="Settings" />
                              </ListItemIcon>
                            </StyledMenuItem>
                          </NavLink> */}
                    <NavLink
                      to="/auth/logout"
                      className={classes.mobileMenuRightToplink}
                      onClick={handleClose}
                    >
                      <StyledMenuItem>
                        <ListItemIcon>
                          <ListItemIcon>
                            <i className="fa-solid fa-power-off"></i>
                          </ListItemIcon>
                          <ListItemText primary="Log Out" />
                        </ListItemIcon>
                      </StyledMenuItem>
                    </NavLink>
                  </StyledMenu>
                </div>
              </Toolbar>
            </AppBar>
          </div>
          <Drawer
            variant="permanent"
            className={clsx({
              [classes.drawerOpen]: drawerState,
              [classes.drawerClose]: !drawerState,
              "menu-drawer-open": drawerState,
              "menu-drawer-close": !drawerState,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: drawerState,
                [classes.drawerClose]: !drawerState,
                "menu-drawer-open": drawerState,
                "menu-drawer-close": !drawerState,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <div className="logo cstmlogosize">
                {sessionStorage.getItem("logo") ? (
                  <>
                    <img
                      src="/images/AcutrqImage.png"
                      alt="Classic"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </>
                ) : (
                  <>
                    {orgLogo ? (
                      <img
                        crossorigin="anonymous"
                        src={`${process.env.REACT_APP_API_URL}${orgLogo}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        alt="logo"
                      />
                    ) : (
                      <img
                        src="/images/AcutrqImage.png"
                        alt="Classic"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <IconButton onClick={toggleDrawer} hidden>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>

            {list()}
          </Drawer>
        </Fragment>
      </Hidden>
    </React.Fragment>
  );
}
