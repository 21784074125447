import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import config from "./config";
import { theme } from "./theme";
import SimpleBackdrop from "./SimpleBackdrop";
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";
import AvailablePolicies from "./AvailablePolicies";
const AuthContext = React.createContext();

class AuthProviderComponent extends React.Component {
  constructor(props) {
    super(props);
    const user = this.getUser();

    let userObj = {
      initials: "",
      user_id: "",
      sub_domain: "",
      first_name: "",
      last_name: "",
      profile_pic: "",
      policies: "",
    };

    const initials =
      user && user.first_name && user.last_name
        ? `${user.first_name[0]}${user.last_name[0]}`
        : "??";
    if (user) {
      userObj = {
        initials: initials,
        user_id: user.user_id,
        sub_domain: user.sub_domain,
        first_name: user.first_name,
        last_name: user.last_name,
        profile_pic: user.profile_pic,
        policies: user.policies,
      };
    }

    this.state = {
      isAuth: user ? true : false,
      rightBarState: false,
      user: userObj,
      title: "App",
      showBackDrop: false,
    };
    this.applicationState$ = 0;
    this.isOnline = true;
  }

  componentDidMount() {
    window.addEventListener("storage", this.applicationState);
    window.addEventListener("online", this.applicationState);
    window.addEventListener("offline", this.applicationState);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.applicationState);
    window.removeEventListener("online", this.applicationState);
    window.removeEventListener("offline", this.applicationState);
  }

  setTitle = (title) => {
    this.setState({
      title: title,
    });
    config.title(title);
  };

  backdrop = (status) => {
    this.setState((prevState) => ({ ...prevState, showBackDrop: status }));
  };

  setRightBar = (status) => {
    // toggle then
    if (status === undefined) {
      status = !this.state.rightBarState;
    }
    this.setState((prevState) => ({ ...prevState, rightBarState: status }));
  };

  hasAccess = (policy) => {
    if (!policy) {
      return true;
    }

    // log missing policy

    if (policy instanceof Array) {
      policy.forEach((item) => {
        if (!AvailablePolicies.includes(item)) {
          console.warn(`${item} not defined`);
        }
      });
    } else if (!AvailablePolicies.includes(policy)) {
      console.warn(`${policy} not defined`);
    }
    // log missing policy

    const userPolicies =
      this.state.user && this.state.user.policies
        ? this.state.user.policies
        : [];
    // super admin has all access
    if (userPolicies.includes("super_admin")) {
      return true;
    }

    if (policy instanceof Array) {
      // array? then return true if one of them exists.);
      return userPolicies.some((item) => policy.includes(item));
    }

    return userPolicies.includes(policy);
  };

  hasPolicy = (policy) => {
    if (!policy) {
      return true;
    }
    policy = Array.isArray(policy) ? policy : [policy];

    const userPolicies =
      this.state.user && this.state.user.policies
        ? this.state.user.policies
        : [];

    // return true if one of them exists
    return userPolicies.some((item) => policy.includes(item));
  };

  applicationState = () => {
    if (this.state.isAuth && !this.getUser()) {
      this.props.snackbar("Logged out from another tab");
      this.logout();
    }

    // Logged in from another tab
    if (!this.state.isAuth && this.getUser()) {
      this.login(this.getUser());
    }

    if (this.isOnline !== navigator.onLine) {
      this.isOnline = navigator.onLine;
      this.props.snackbar(
        <Trans i18nKey={`network.${this.isOnline}`}></Trans>,
        {
          variant: this.isOnline ? "success" : "error",
        }
      );
    }
  };

  getUser() {
    try {
      return JSON.parse(localStorage.getItem("user"));
    } catch (error) {
      if (error instanceof SyntaxError) {
        localStorage.removeItem("user");
        return null;
      }
    }
  }

  login = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    const initials =
      user && user.first_name && user.last_name
        ? `${user.first_name[0]}${user.last_name[0]}`
        : "??";
    this.setState({
      isAuth: true,
      user: {
        ...user,
        initials: initials,
      },
    });
  };

  logout = () => {
    localStorage.clear();
    this.setState({
      isAuth: false,
      user: {
        initials: "",
        user_id: "",
        sub_domain: "",
        first_name: "",
        last_name: "",
        profile_pic: "",
        policies: [],
      },
    });
  };
  impersonating = () => {
    const user = this.getUser();
    if (user?.policies?.includes("super_admin") && user?.isImpersonating) {
      return true;
    }
    if (!user?.policies?.includes("super_admin")) {
      return true;
    }
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <SimpleBackdrop hidden={!this.state.showBackDrop} />
        <AuthContext.Provider
          value={{
            isAuth: this.state.isAuth,
            isImpersonating: this.impersonating(),
            user: this.state.user,
            hasAccess: this.hasAccess,
            hasPolicy: this.hasPolicy,
            setRightBar: this.setRightBar,
            rightBarState: this.state.rightBarState,
            setTitle: this.setTitle,
            title: this.state.title,
            login: this.login,
            logout: this.logout,
            snackbar: this.props.snackbar,
            backdrop: this.backdrop,
          }}
        >
          {this.props.children}
        </AuthContext.Provider>
      </ThemeProvider>
    );
  }
}

// functional components - translations
const translations = [];

const AuthProvider = withTranslation(translations)(AuthProviderComponent);
// const AuthProvider = (AuthProviderComponent)
const AuthConsumer = AuthContext.Consumer;
export { AuthProvider, AuthConsumer, AuthContext };
