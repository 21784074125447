import React, { createContext, useEffect } from "react";
import { useState } from "react";
import http from "../../utils/http";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import {
  updateformPreset,
  formPresetById,
  updateLibraryStatus,
} from "../Utility/ApiMethod";
import { toast } from "react-toastify";
export const FormPreSet = createContext();

const Contex = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const [enable, setEnable] = useState(true);
  const [paramId, setParamId] = useState("");
  const [formdata, setFormdata] = useState({});
  const [ids, setIds] = useState([]);
  const [postDisclouserIds, setPostDisclouserIds] = useState([]);

  /*----- Upload  state ---*/
  const [enableupload, setEnableUpload] = useState(true);
  /*----- End of Upload  state---*/

  /*----- Confirmation section ---*/
  const [displayMsg, setDisplayMsg] = useState(true);
  const [displayLink, setDisplayLink] = useState(false);
  const [welcomeMsg, setWelcomeMsg] = useState("");
  const [uploadMsg, setUploadMsg] = useState("");
  const [incomeMsg, setIncomeMsg] = useState("");
  const [enableInt, setEnableInt] = useState("");
  /*----- End of Questionnaries toggle state ---*/

  /*----- End of Questionnaries toggle state ---*/

  const updateLibrary = async (id) => {
    await updateLibraryStatus(id, { inUse: true });
    let uid = [...ids];
    uid.push(id);
    setIds(uid);
    const payload = {
      library_ids: uid,
    };
    const libraryData = await updateformPreset(paramId, payload);

    enqueueSnackbar("Library template added successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
    fetchData();
  };
  const deleteLibrary = async (index) => {
    let uid = [...ids];
    const removedId = uid.splice(index, 1);
    await updateLibraryStatus(...removedId, { inUse: false });
    setIds(uid);
    const payload = {
      library_ids: uid,
    };

    await updateformPreset(paramId, payload);

    enqueueSnackbar("Library template removed successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
    fetchData();
  };

  const updatePostDiscId = async (id) => {
    await updateLibraryStatus(id, { inUsePost: true });
    let uid = [...postDisclouserIds];
    uid.push(id);
    setPostDisclouserIds(uid);
    const payload = {
      postdisclouser_ids: uid,
    };
    await updateformPreset(paramId, payload);
    enqueueSnackbar("Library template added successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
    fetchData();
  };

  const deletePostDiscId = async (index) => {
    let uid = [...postDisclouserIds];
    const removedId = uid.splice(index, 1);
    await updateLibraryStatus(...removedId, { inUsePost: false });
    setPostDisclouserIds(uid);
    const payload = {
      postdisclouser_ids: uid,
    };

    await updateformPreset(paramId, payload);
    enqueueSnackbar("Library template removed successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
    fetchData();
  };

  // End of Welcome message

  const handleChange = async (event) => {
    const { name, value } = event.target;

    setFormdata({ ...formdata, [name]: value });
  };
  const handleBlurSubmit = async (event) => {
    let data;
    const { name, value } = event.target;

    data = { ...formdata, [name]: value };
    const payload = {
      [name]: value,
    };
    setFormdata(data);
    try {
      await updateformPreset(paramId, payload);
      enqueueSnackbar("Data updated successfully", {
        variant: "success",
        autoHideDuration: 1000,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCLickSubmit = async (event) => {
    let data;
    const { name, checked } = event.target;
    data = { ...formdata, [name]: checked };
    setFormdata(data);
    await updateformPreset(paramId, data);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };

  /*----- Welcome Message Implementation ---*/

  const handleWelcomeMsg = async (e) => {
    if (welcomeMsg !== "") {
      let payload = {
        welcome_msg: welcomeMsg,
      };

      await updateformPreset(paramId, payload);
      enqueueSnackbar("Data updated successfully", {
        variant: "success",
        autoHideDuration: 1000,
      });
    }
    fetchData();
  };
  const handleUploadMsg = async (e) => {
    if (uploadMsg !== "") {
      let payload = {
        upload_msg: uploadMsg,
      };

      await updateformPreset(paramId, payload);
      enqueueSnackbar("Data updated successfully", {
        variant: "success",
        autoHideDuration: 1000,
      });
    }
    fetchData();
  };
  const handleIncomeMsg = async (e) => {
    if (incomeMsg !== "") {
      let payload = {
        income_msg: incomeMsg,
      };

      await updateformPreset(paramId, payload);
      enqueueSnackbar("Data updated successfully", {
        variant: "success",
        autoHideDuration: 1000,
      });
    }
    fetchData();
  };

  /*----- End of Welcome Message Implementation ---*/

  /*----- Jurisdiction question state ---*/

  const [enableJud, setEnableJud] = useState(true);
  const [inputFields, setInputFields] = useState(["New Question"]);

  const addInputField = async () => {
    setInputFields([...inputFields, "New Question"]);
    let data = [...inputFields, "New Question"];
    const payload = {
      jud_questions: data,
    };

    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };
  const handleOnChange = async (e, index) => {
    let tempInputField = [...inputFields];
    tempInputField.splice(index, 1, e.target.value);
    setInputFields(tempInputField);
  };

  const handleSubmitArray = async (e, index, id) => {
    const payload = {
      jud_questions: inputFields,
    };

    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };
  const removeInputFields = async (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
    const payload = {
      jud_questions: rows,
    };

    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };

  /*----- End of Jurisdiction question state---*/

  /*----- Questionnaries toggle state ---*/

  const [hideVechile, setHideVechile] = useState(false);
  const [hidePet, setHidePet] = useState(false);
  const [hidePersonalRef, setHidePersonalRef] = useState(false);
  const [hideBankAccount, setHideBankAccount] = useState(false);
  const [hideEmergencyContact, setHideEmergencyContact] = useState(false);
  const [hideYesNoQuest, setHideYesNoQuest] = useState(false);

  /*----- End of Questionnaries toggle state ---*/

  /*----- Upload Section Implementaion ---*/
  const [uploadData, setUploadData] = useState([]);
  const addUploadRow = async () => {
    setUploadData([
      ...uploadData,
      {
        requestdoc_name: "New Requested File",
        doc_required: "",
        doctype: "Collect once",
        type: "Standard upload",
      },
    ]);
    let data = [
      ...uploadData,
      {
        requestdoc_name: "New Requested File",
        doc_required: "",
        doctype: "Collect once",
        type: "Standard upload",
      },
    ];

    let payload = {
      requested_files: data,
    };

    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };
  const deleteUploadRow = async (index) => {
    const rows = [...uploadData];
    rows.splice(index, 1);
    setUploadData(rows);
    let payload = {
      requested_files: rows,
    };

    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };
  const handleUploadChange = async (e, i) => {
    let onChangeValue;
    const { name, value } = e.target;
    onChangeValue = [...uploadData];
    onChangeValue[i][name] = value;
    const incomeLength = onChangeValue.filter((file) => {
      return file.type === "Income proof";
    });
    const IdLength = onChangeValue.filter((file) => {
      return file.type === "Id proof";
    });
    if (incomeLength.length <= 3 && IdLength.length <= 1) {
      setUploadData(onChangeValue);
      let payload = {
        requested_files: onChangeValue,
      };

      await updateformPreset(paramId, payload);
      enqueueSnackbar("Data updated successfully", {
        variant: "success",
        autoHideDuration: 1000,
      });
    } else {
      if (IdLength > 1) {
        enqueueSnackbar(
          "You can't have more than 1 Identity Verification files",
          {
            variant: "error",
            autoHideDuration: 1000,
          }
        );
      } else {
        enqueueSnackbar(
          "You can't have more than 3 Document Verification files",
          {
            variant: "error",
            autoHideDuration: 1000,
          }
        );
      }
    }
  };

  const handleUploadCheckbox = async (e, i) => {
    let onChangeValue;
    const { name, checked } = e.target;
    onChangeValue = [...uploadData];
    onChangeValue[i][name] = checked;
    setUploadData(onChangeValue);
    let payload = {
      requested_files: onChangeValue,
    };

    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };

  /*----- End of Upload Section Implementaion ---*/

  /*----- Co-applicant section ---*/

  const [showCheckbox, setShowCheckbox] = useState(false);
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);

  const handleHideShow = async (e) => {
    setCheckedOne(!checkedOne);
    setShowCheckbox(!showCheckbox);
  };

  /*----- end of Co-applicant section ---*/

  /*----- Welcome Confirmation Implementation ---*/
  const [confirmMsg, setConfirmMsg] = useState("");

  const handleConfirmMsg = async (e) => {
    let payload = {
      confirmation_message: confirmMsg,
    };

    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
    fetchData();
  };

  /*----- End of Welcome Message Implementation ---*/

  /*----- Custom Questions Implementation ---*/

  const [customQuestion, setCustomQuestion] = useState([]);

  const addCustomFiled = async () => {
    setCustomQuestion([
      ...customQuestion,
      {
        custom_question: "",
        answer_prompt: "",
        should_prompt: false,
      },
    ]);

    let data = [
      ...customQuestion,
      {
        custom_question: "",
        answer_prompt: "",
        should_prompt: false,
      },
    ];
    let payload = {
      custom_questions: data,
    };
    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };
  const removeCustomFiled = async (index) => {
    const rows = [...customQuestion];
    rows.splice(index, 1);
    setCustomQuestion(rows);
    let payload = { custom_questions: rows };
    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };

  const handleChangeCustom = async (e, index) => {
    let tempInputField = [...customQuestion];
    tempInputField.splice(index, 1, e.target.value);
    const { name, value } = e.target;
    tempInputField = [...customQuestion];
    tempInputField[index][name] = value;
    setInputFields(tempInputField);
  };

  const handleChangeChekboxCustom = async (e, index) => {
    let tempInputField = [...customQuestion];
    tempInputField.splice(index, 1, e.target.value);
    const { name, checked } = e.target;
    tempInputField = [...customQuestion];
    tempInputField[index][name] = checked;
    setInputFields(tempInputField);
    const payload = {
      custom_questions: tempInputField,
    };
    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };
  const handleChangeEnable = async (e, index) => {
    const { name, checked } = e.target;
    const payload = {
      enable_international_add: checked,
    };
    setEnableInt(checked);
    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };

  const handleSubmitCustom = async (e, index) => {
    const payload = {
      custom_questions: customQuestion,
    };
    await updateformPreset(paramId, payload);
    enqueueSnackbar("Data updated successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };

  /*----- End of Custom QuestionsImplementation ---*/

  async function fetchData(id) {
    try {
      const { data } = await formPresetById(id || paramId);

      if (data.success) {
        setFormdata(data.FormPresetData);
        setInputFields(data.FormPresetData.jud_questions);
        setUploadData(data.FormPresetData.requested_files);
        setCheckedOne(data.FormPresetData.enable_occupants);
        setCheckedTwo(data.FormPresetData.dependent_collect_dob_on_all);
        setCheckedThree(data.FormPresetData.enable_guarantor);
        setIds(data.FormPresetData.library_ids);
        setPostDisclouserIds(data.FormPresetData.postdisclouser_ids);
        setCustomQuestion(data.FormPresetData.custom_questions);
        setEnable(data.FormPresetData.propInfo);
      } else {
        alert("No record found");
      }
    } catch (error) {
      console.log(error);
    }
  }

  // useEffect(() => {
  //   setEnable(false);
  // }, []);

  return (
    <FormPreSet.Provider
      value={{
        enable,
        setEnable,
        formdata,
        setFormdata,
        handleChange,
        handleBlurSubmit,
        setParamId,
        fetchData,
        enableJud,
        setEnableJud,
        hideVechile,
        setHideVechile,
        hidePet,
        setHidePet,
        hidePersonalRef,
        setHidePersonalRef,
        hideBankAccount,
        setHideBankAccount,
        hideEmergencyContact,
        setHideEmergencyContact,
        hideYesNoQuest,
        setHideYesNoQuest,
        inputFields,
        setInputFields,
        addInputField,
        handleOnChange,
        removeInputFields,
        enableupload,
        setEnableUpload,
        displayMsg,
        setDisplayMsg,
        displayLink,
        setDisplayLink,
        handleWelcomeMsg,
        handleUploadMsg,
        handleIncomeMsg,
        uploadData,
        setUploadData,
        addUploadRow,
        deleteUploadRow,
        handleUploadChange,
        showCheckbox,
        setShowCheckbox,
        checkedOne,
        setCheckedOne,
        checkedTwo,
        setCheckedTwo,
        checkedThree,
        setCheckedThree,
        handleHideShow,
        handleConfirmMsg,
        setWelcomeMsg,
        setUploadMsg,
        setIncomeMsg,
        setConfirmMsg,
        handleCLickSubmit,
        handleSubmitArray,
        updateLibrary,
        deleteLibrary,
        ids,
        customQuestion,
        addCustomFiled,
        removeCustomFiled,
        handleChangeCustom,
        handleSubmitCustom,
        handleChangeChekboxCustom,
        handleUploadCheckbox,
        postDisclouserIds,
        updatePostDiscId,
        deletePostDiscId,
        handleChangeEnable,
        setEnableInt,
        enableInt,
      }}
    >
      {children}
    </FormPreSet.Provider>
  );
};

export default Contex;
